import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import '../../../../pli/pli-icon';
import { baseStyles, gridStyles, flexStyles } from 'pli';

export type Option = {
    value: string;
    label: string;
};

@customElement('single-select')
class SingleSelect extends LitElement {
    static styles = [
        css`
            .select {
                position: relative;
            }

            select {
                appearance: none;
                padding: var(--size-0-5) var(--size-1);
                padding-right: var(--size-2-5);
                border-radius: var(--radius-md);
                background-color: var(--body-bg);
                border: var(--border-default);
                width: 100%;
            }

            .icon-wrapper {
                position: absolute;
                height: 100%;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                padding: 0 var(--size-1);
                pointer-events: none;
            }
        `,
    ];

    @property({ type: Array })
    options: Option[];

    @property()
    placeholder: string;

    @property()
    value?: string;

    _emit = (event: Event) => {
        const value = (event.currentTarget as HTMLSelectElement).value;

        this.dispatchEvent(
            new CustomEvent('change', {
                composed: true,
                detail: {
                    value,
                },
            }),
        );
    };

    render() {
        const { placeholder, options, value } = this;
        return html` <div class="select">
            <div class="icon-wrapper"><pli-icon name="chevron-down"></pli-icon></div>
            <select @change="${this._emit}">
                <option value="" disabled selected>${placeholder}</option>
                ${options.map(
                    (option) => html`
                        <option value="${option.value}" .selected="${value === option.value}">${option.label}</option>
                    `,
                )}
            </select>
        </div>`;
    }
}
