import { LitElement, PropertyValues, TemplateResult, css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../../../pli/pli-icon';
import './single-select';
import { flexStyles, gridStyles } from 'pli';
import { consume } from '@lit/context';
import { HttpClient } from 'aurelia-fetch-client';
import { clientContext } from 'context/client-context';
import '../../../../pli/pli-skeleton';
import { Ref, createRef } from 'lit/directives/ref.js';
import { ValueType } from 'rules/poc/types/rules-details-types';
import '../../../../pli/pli-input';
import './searchable-combobox';
import { Option } from 'rules/poc/components/controls/single-select'

/*
 * Config
 */
const avaliableOptions: { value: ValueType; label: string }[] = [
    { value: 'ByValue', label: 'By Value' },
    { value: 'ByCustomerKyc', label: 'By Kyc' },
    { value: 'ByCustomerProperty', label: 'By Customer' },
];
const placeholders: Record<ValueType, string> = {
    ByValue: 'Set value',
    ByCustomerKyc: 'Set value type',
    ByCustomerProperty: 'Set value type',
};

@customElement('select-value-type')
class SelectValueType extends LitElement {
    @consume({ context: clientContext, subscribe: true })
    @property({ attribute: false })
    public client?: HttpClient;

    @property()
    value?: string;

    @property()
    valueType?: string;

    _options = avaliableOptions;

    static styles = [
        flexStyles,
        gridStyles,
        css`
            .value-type {
                flex-wrap: wrap;
            }

            input {
                appearance: none;
                padding: var(--size-0-5) var(--size-1);
                border-radius: var(--radius-md);
                background-color: var(--body-bg);
                border: var(--border-default);
            }

            pli-input {
                display: inline-flex;
                width: auto;
            }
        `,
    ];

    @state()
    _comboBoxInputValue = null;
    @state()
    _items = [];
    @state()
    _valueType = '';
    @state()
    _value = '';

    @state()
    _customerKycOptions: Option[] = [];

    @state()
    _customerPropertyOptions: Option[] = [];   

    emit({ value, valueType }: { value: string; valueType: ValueType }) {
        this.dispatchEvent(
            new CustomEvent('valueTypeUpdate', {
                composed: true,
                detail: {
                    value,
                    valueType,
                },
            }),
        );
    }

    connectedCallback(): void {
        super.connectedCallback();
        this._valueType = this.valueType ?? '';
        this._value = this.value ?? '';
    }

    handleValueTypeChange = async (event: CustomEvent) => {
        if (this._valueType !== event.detail.value) this._value = '';
        event.stopPropagation();
        const value = event.detail.value;
        this._valueType = value;

        // run task manually to fetch async options
        this.emit({ value: this.value, valueType: value });

        // wait for new input to be attached to DOM
        this.requestUpdate();
        await this.updateComplete;
    };

    handleValueChange = (event: CustomEvent) => {
        event.stopPropagation();
        const value = event.detail.value;
        this._value = value;
        this.emit({ value, valueType: this.valueType as ValueType });
    };

    renderInputNumber = () => {
        return html`<pli-input
            value="${this._value}"
            @change="${this.handleValueChange}"
            placeholder="${placeholders[this.valueType]}"
            size="sm"
        ></pli-input>`;
    };

    protected async firstUpdated(_changedProperties: PropertyValues): Promise<void> {
        const fetch = async (url: string): Promise<Option[]> => {
            const result = await this.client.get(url);
            const json = (await result.json());

            return json.map(m => <Option>{ label: m.name, value: m.name });
        }

        this._customerKycOptions = await fetch("customer/kyc/properties");
        this._customerPropertyOptions = await fetch("customer/properties");
    }

    render() {
        const { _options, valueType, handleValueTypeChange, renderInputNumber, renderKycSelect, renderPropertySelect } =
            this;

        const controlsMap: Record<ValueType, TemplateResult<1>> = {
            ByValue: renderInputNumber(),
            ByCustomerKyc: renderKycSelect(),
            ByCustomerProperty: renderPropertySelect(),
        };

        return html`<div class="value-type flex items-center gap-1">
            <single-select
                value="${valueType}"
                @change="${handleValueTypeChange}"
                .options="${_options}"
                placeholder="Select value type"
            ></single-select>
            ${controlsMap[valueType] ?? null}
        </div>`;
    }

    renderPropertySelect = () => {
        return html`<single-select
            placeholder="Select property"
            value="${this._value}"
            .options="${this._customerPropertyOptions}"
            @change="${this.handleValueChange}"
        ></single-select>`;
    };

    renderKycSelect = () => {
        return html`<single-select
            placeholder="Select KYC property"
            value="${this._value}"
            .options="${this._customerKycOptions}"
            @change="${this.handleValueChange}"
        ></single-select>`;
    };
}
